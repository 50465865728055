$brand-color: rgb(236, 236, 236);
$secondary-brand-color: rgb(236, 236, 236);
$accent-color: #8E292F;
$accent-color-highlight: #914c50;


$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);
$text-color: #263238;

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";
